import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_a_select, {
      value: _ctx.computedValor,
      "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.computedValor) = $event)),
      onChange: _ctx.defineValorSelecionado,
      class: "ui-full",
      disabled: _ctx.props.disabled
    }, {
      default: _withCtx(() => [
        ((_ctx.props.omitir !== _ctx.EStatusPedido.PedidoEfetuado))
          ? (_openBlock(), _createBlock(_component_a_select_option, {
              key: 0,
              value: _ctx.EStatusPedido.PedidoEfetuado
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.servicoPedido.obterDescricaoStatus(_ctx.EStatusPedido.PedidoEfetuado)), 1)
              ]),
              _: 1
            }, 8, ["value"]))
          : _createCommentVNode("", true),
        ((_ctx.props.omitir !== _ctx.EStatusPedido.AguardandoPagamento))
          ? (_openBlock(), _createBlock(_component_a_select_option, {
              key: 1,
              value: _ctx.EStatusPedido.AguardandoPagamento
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.servicoPedido.obterDescricaoStatus(_ctx.EStatusPedido.AguardandoPagamento)), 1)
              ]),
              _: 1
            }, 8, ["value"]))
          : _createCommentVNode("", true),
        ((_ctx.props.omitir !== _ctx.EStatusPedido.PagamentoConfirmado))
          ? (_openBlock(), _createBlock(_component_a_select_option, {
              key: 2,
              value: _ctx.EStatusPedido.PagamentoConfirmado
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.servicoPedido.obterDescricaoStatus(_ctx.EStatusPedido.PagamentoConfirmado)), 1)
              ]),
              _: 1
            }, 8, ["value"]))
          : _createCommentVNode("", true),
        ((_ctx.props.omitir !== _ctx.EStatusPedido.PedidoFaturado))
          ? (_openBlock(), _createBlock(_component_a_select_option, {
              key: 3,
              value: _ctx.EStatusPedido.PedidoFaturado
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.servicoPedido.obterDescricaoStatus(_ctx.EStatusPedido.PedidoFaturado)), 1)
              ]),
              _: 1
            }, 8, ["value"]))
          : _createCommentVNode("", true),
        ((_ctx.props.omitir !== _ctx.EStatusPedido.EmPreparacao))
          ? (_openBlock(), _createBlock(_component_a_select_option, {
              key: 4,
              value: _ctx.EStatusPedido.EmPreparacao
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.servicoPedido.obterDescricaoStatus(_ctx.EStatusPedido.EmPreparacao)), 1)
              ]),
              _: 1
            }, 8, ["value"]))
          : _createCommentVNode("", true),
        ((_ctx.props.omitir !== _ctx.EStatusPedido.AguardandoRetirada))
          ? (_openBlock(), _createBlock(_component_a_select_option, {
              key: 5,
              value: _ctx.EStatusPedido.AguardandoRetirada
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.servicoPedido.obterDescricaoStatus(_ctx.EStatusPedido.AguardandoRetirada)), 1)
              ]),
              _: 1
            }, 8, ["value"]))
          : _createCommentVNode("", true),
        ((_ctx.props.omitir !== _ctx.EStatusPedido.EnviadoParaTransportadora))
          ? (_openBlock(), _createBlock(_component_a_select_option, {
              key: 6,
              value: _ctx.EStatusPedido.EnviadoParaTransportadora
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.servicoPedido.obterDescricaoStatus(_ctx.EStatusPedido.EnviadoParaTransportadora)), 1)
              ]),
              _: 1
            }, 8, ["value"]))
          : _createCommentVNode("", true),
        ((_ctx.props.omitir !== _ctx.EStatusPedido.RecebidoPelaTransportadora))
          ? (_openBlock(), _createBlock(_component_a_select_option, {
              key: 7,
              value: _ctx.EStatusPedido.RecebidoPelaTransportadora
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.servicoPedido.obterDescricaoStatus(_ctx.EStatusPedido.RecebidoPelaTransportadora)), 1)
              ]),
              _: 1
            }, 8, ["value"]))
          : _createCommentVNode("", true),
        ((_ctx.props.omitir !== _ctx.EStatusPedido.EmRotaDeEntrega))
          ? (_openBlock(), _createBlock(_component_a_select_option, {
              key: 8,
              value: _ctx.EStatusPedido.EmRotaDeEntrega
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.servicoPedido.obterDescricaoStatus(_ctx.EStatusPedido.EmRotaDeEntrega)), 1)
              ]),
              _: 1
            }, 8, ["value"]))
          : _createCommentVNode("", true),
        ((_ctx.props.omitir !== _ctx.EStatusPedido.Entregue))
          ? (_openBlock(), _createBlock(_component_a_select_option, {
              key: 9,
              value: _ctx.EStatusPedido.Entregue
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.servicoPedido.obterDescricaoStatus(_ctx.EStatusPedido.Entregue)), 1)
              ]),
              _: 1
            }, 8, ["value"]))
          : _createCommentVNode("", true),
        ((_ctx.props.omitir !== _ctx.EStatusPedido.Cancelado))
          ? (_openBlock(), _createBlock(_component_a_select_option, {
              key: 10,
              value: _ctx.EStatusPedido.Cancelado
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.servicoPedido.obterDescricaoStatus(_ctx.EStatusPedido.Cancelado)), 1)
              ]),
              _: 1
            }, 8, ["value"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["value", "onChange", "disabled"])
  ]))
}