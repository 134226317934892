export enum EStatusPedido {
    PedidoEfetuado = 1,
    AguardandoPagamento = 2,
    PagamentoConfirmado = 3,
    PedidoFaturado = 4,
    EmPreparacao = 5,
    AguardandoRetirada = 6,
    EnviadoParaTransportadora = 7,
    RecebidoPelaTransportadora = 8,
    EmTransito = 9,
    EmRotaDeEntrega = 10,
    Entregue = 11,
    Cancelado = 12
}
