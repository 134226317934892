
import {
  defineComponent, onBeforeMount, reactive,
} from 'vue';
import { useRoute } from 'vue-router';
import { useAppBase } from '@/core/composables/AppBase';
import { IPedido, IPedidoHistorico } from '@/models/Entidades/IPedido';
import ComunicacaoApi from '@/core/components/UI/ComunicacaoApi.vue';
import ServicoPedido from '@/servicos/ServicoPedido';
import { IResposta } from '@/core/models/IResposta';
import { EStatusPedido } from '@/models/Enumeradores/EStatusPedido';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import UtilitarioData from '@/core/utilitarios/UtilitarioData';
import ComboStatusPedido from '@/components/Pedidos/ComboStatusPedido.vue';
import { EStatusResposta } from '@/models/Enumeradores/EStatusResposta';

export default defineComponent({
  name: 'PedidoCadastro',
  components: {
    ComunicacaoApi,
    ComboStatusPedido,
  },
  setup() {
    const {
      appBase, apresentarComunicacaoApi, apresentarResposta,
    } = useAppBase();

    const route = useRoute();
    const servicoPedido = new ServicoPedido();

    const state = reactive({
      pedido: {} as IPedido,
      apresentarModalStatus: false,
      formasEntregasSelecionadas: [] as number[],
      historico: {} as IPedidoHistorico,
    });

    async function obterPedido(numeroIdentificacao:string) {
      appBase.carregando = true;
      state.pedido = await servicoPedido.obterPorNumeroIdentificacao(numeroIdentificacao);
      appBase.carregando = false;
    }

    function limparDados() {
      state.formasEntregasSelecionadas = [];
      state.pedido = {} as IPedido;
      state.pedido.codigo = 0;
      state.historico = {} as IPedidoHistorico;
      state.historico.codigo = 0;
      state.historico.codigoPedido = 0;
    }

    onBeforeMount(async () => {
      limparDados();

      if (route.params !== undefined && UtilitarioGeral.valorValido(route.params.numeroIdentificacao.toString())) {
        await obterPedido(route.params.numeroIdentificacao.toString());
      }
    });

    async function atualizarStatusPedido() {
      appBase.resposta = {} as IResposta;
      apresentarComunicacaoApi('Aguarde por favor... Estamos salvando as informações do Pedido.');
      state.historico.codigoPedido = state.pedido.codigo;
      appBase.resposta = await servicoPedido.atualizarStatusHistorico(state.historico);
      if (appBase.resposta.status === EStatusResposta.Sucesso) {
        state.pedido.status = state.historico.status;
        state.historico = {} as IPedidoHistorico;
        state.historico.codigo = 0;
        state.historico.detalhes = '';
        state.apresentarModalStatus = false;
      }
      apresentarResposta();
    }

    function obterDescricaoStatus(status:number):string {
      return servicoPedido.obterDescricaoStatus(status);
    }

    function obterLabelCpfCnpj():string {
      return state.pedido.cpfCnpj.length === 11 ? 'CPF' : 'CNPJ';
    }

    function totalProdutos():number {
      let valorTotalProdutos = 0;
      if (UtilitarioGeral.validaLista(state.pedido.itens)) {
        state.pedido.itens.forEach((item) => {
          valorTotalProdutos += item.valorTotal;
        });
      }
      return valorTotalProdutos;
    }

    return {
      appBase,
      state,
      EStatusPedido,
      UtilitarioMascara,
      UtilitarioData,
      obterDescricaoStatus,
      obterLabelCpfCnpj,
      totalProdutos,
      atualizarStatusPedido,
    };
  },
});
