import ApiCommerce from '@/core/apis/ApiCommerce';
import { IResposta } from '@/core/models/IResposta';
import { IServicoBase } from '@/core/models/IServicoBase';
import { IRepostaConsulta } from '@/core/models/IRepostaConsulta';
import { IPedidoParametrosConsulta } from '@/models/ParametrosRequisicao/Consulta/IPedidoParametrosConsulta';
import { IPedido, IPedidoHistorico } from '@/models/Entidades/IPedido';
import { IParametrosBuscaRapida } from '@/core/models/Consulta/IParametrosBuscaRapida';
import { IItemBuscaRapida } from '@/core/models/Consulta/IItemBuscaRapida';
import { IDTOPedido } from '@/models/DTO/IDTOPedido';
import { EStatusPedido } from '@/models/Enumeradores/EStatusPedido';
import { IDTOPedidoTotalizador } from '@/models/DTO/IDTOPedidoTotalizador';
/**
 * Serviço de Pedidos
 */

class ServicoPedido implements IServicoBase<IPedido> {
  endPoint = 'pedidos';

  apiCommerce = new ApiCommerce();

  public async incluir(pedido: IPedido): Promise<IResposta> {
    const result: any = await this.apiCommerce.post(`${this.endPoint}`, pedido);
    return result.data;
  }

  public async atualizar(pedido: IPedido): Promise<IResposta> {
    const result: any = await this.apiCommerce.put(`${this.endPoint}/${pedido.codigo}`, pedido);
    return result.data;
  }

  public async atualizarStatusHistorico(pedidoHistorico: IPedidoHistorico): Promise<IResposta> {
    const result: any = await this.apiCommerce.patch(`${this.endPoint}/${pedidoHistorico.codigoPedido}/status/${pedidoHistorico.status}`, pedidoHistorico);
    return result.data;
  }

  public async obter(codigo: number): Promise<IPedido> {
    const result: any = await this.apiCommerce.get(`${this.endPoint}/${codigo}`);
    return result.data;
  }

  public async obterPorNumeroIdentificacao(numeroIdentificacao: string): Promise<IPedido> {
    const result: any = await this.apiCommerce.get(`${this.endPoint}/numero-identificacao/${numeroIdentificacao}`);
    return result.data;
  }

  public async obterTotalizadores(): Promise<IDTOPedidoTotalizador[]> {
    const result: any = await this.apiCommerce.get(`${this.endPoint}/totalizadores`);
    return result.data;
  }

  public async obterPedidos(parametrosConsulta: IPedidoParametrosConsulta): Promise<IRepostaConsulta<IDTOPedido>> {
    let parametrosAdicionais = '';

    if (parametrosConsulta.valorPesquisa !== undefined) {
      parametrosAdicionais += `?ValorPesquisa=${parametrosConsulta.valorPesquisa}`;
    }

    if (parametrosConsulta.status !== undefined && parametrosConsulta.status > 0) {
      parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
      parametrosAdicionais += `Status=${parametrosConsulta.status}`;
    }

    if (parametrosConsulta.numeroPagina !== undefined && parametrosConsulta.numeroPagina > 0) {
      parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
      parametrosAdicionais += `NumeroPagina=${parametrosConsulta.numeroPagina}`;
    }
    if (parametrosConsulta.qtdeRegistrosPagina !== undefined && parametrosConsulta.qtdeRegistrosPagina > 0) {
      parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
      parametrosAdicionais += `QtdeRegistrosPagina=${parametrosConsulta.qtdeRegistrosPagina}`;
    }
    if (parametrosConsulta.qtdeRegistrosTotal !== undefined && parametrosConsulta.qtdeRegistrosTotal > 0) {
      parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
      parametrosAdicionais += `QtdeRegistrosTotal=${parametrosConsulta.qtdeRegistrosTotal}`;
    }
    if (parametrosConsulta.ordenacao !== undefined) {
      parametrosConsulta.ordenacao.forEach((item) => {
        parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
        parametrosAdicionais += `Ordenacao=${item}`;
      });
    }
    if (parametrosConsulta.codigos !== undefined) {
      parametrosConsulta.codigos.forEach((codigo) => {
        parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
        parametrosAdicionais += `Codigos=${codigo}`;
      });
    }
    const result: any = await this.apiCommerce.get(`${this.endPoint}${parametrosAdicionais}`);
    return result.data;
  }

  public async buscaRapida(parametrosConsultaRapida: IParametrosBuscaRapida): Promise<IItemBuscaRapida[]> {
    const parametros = this.apiCommerce.obterParametrosBuscaRapida(parametrosConsultaRapida);
    const result: any = await this.apiCommerce.get(`${this.endPoint}/busca-rapida${parametros}`);
    return result.data;
  }

  public async deletar(codigo: number): Promise<IResposta> {
    const result: any = await this.apiCommerce.delete(`${this.endPoint}/${codigo}`);
    return result.data;
  }

  public obterDescricaoStatus(status:EStatusPedido):string {
    let descricao = '';
    switch (status) {
      case EStatusPedido.PedidoEfetuado:
        descricao = 'Pedido Efetuado';
        break;

      case EStatusPedido.AguardandoPagamento:
        descricao = 'Aguardando Pagamento';
        break;

      case EStatusPedido.PagamentoConfirmado:
        descricao = 'Pagamento Confirmado';
        break;

      case EStatusPedido.PedidoFaturado:
        descricao = 'Pedido Faturado';
        break;

      case EStatusPedido.EmPreparacao:
        descricao = 'Em Preparação';
        break;

      case EStatusPedido.AguardandoRetirada:
        descricao = 'Aguardando Retirada';
        break;

      case EStatusPedido.EnviadoParaTransportadora:
        descricao = 'Enviado para Transportadora';
        break;

      case EStatusPedido.RecebidoPelaTransportadora:
        descricao = 'Recebido pela Transportadora';
        break;

      case EStatusPedido.EmTransito:
        descricao = 'Em Trânsito';
        break;

      case EStatusPedido.EmRotaDeEntrega:
        descricao = 'Em rota de Entrega';
        break;

      case EStatusPedido.Entregue:
        descricao = 'Pedido Entregue';
        break;

      case EStatusPedido.Cancelado:
        descricao = 'Cancelado';
        break;

      default:
        break;
    }

    return descricao;
  }

  public obterCorStatus(status:EStatusPedido):string {
    let cor = '';
    switch (status) {
      case EStatusPedido.PedidoEfetuado:
        cor = '#39DC17';
        break;

      case EStatusPedido.AguardandoPagamento:
        cor = '#FFB41B';
        break;

      case EStatusPedido.PagamentoConfirmado:
        cor = '#39DC17';
        break;

      case EStatusPedido.PedidoFaturado:
        cor = '#2551B8';
        break;

      case EStatusPedido.EmPreparacao:
        cor = '#2551B8';
        break;

      case EStatusPedido.AguardandoRetirada:
        cor = '#749CF9';
        break;

      case EStatusPedido.EnviadoParaTransportadora:
        cor = '#2551B8';
        break;

      case EStatusPedido.RecebidoPelaTransportadora:
        cor = '#39DC17';
        break;

      case EStatusPedido.EmTransito:
        cor = '#AF14B4';
        break;

      case EStatusPedido.EmRotaDeEntrega:
        cor = '#720076';
        break;

      case EStatusPedido.Entregue:
        cor = '#009100';
        break;

      case EStatusPedido.Cancelado:
        cor = '#FF1B1B';
        break;

      default:
        break;
    }

    return cor;
  }
}

export default ServicoPedido;
