
import { computed, defineComponent } from 'vue';
import ServicoPedido from '@/servicos/ServicoPedido';
import { EStatusPedido } from '@/models/Enumeradores/EStatusPedido';

export default defineComponent({
  name: 'ComboStatusPedido',
  components: {
  },
  props: {
    valor: {
      type: Number || undefined,
      default: 0,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    omitir: {
      type: Number,
      default: 0,
    },
  },
  emits: ['update:valor', 'change'],
  setup(props, { emit }) {
    const servicoPedido = new ServicoPedido();

    function obtemValor():any {
      if (props.valor === 0) { return undefined; }

      return props.valor;
    }
    const computedValor = computed({
      get: () => obtemValor(),
      set: (valor: number) => {
        emit('update:valor', valor);
      },
    });

    function defineValorSelecionado(valor:number) {
      emit('update:valor', valor);
      emit('change', valor);
    }

    return {
      props,
      computedValor,
      defineValorSelecionado,
      servicoPedido,
      EStatusPedido,
    };
  },
});
