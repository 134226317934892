import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, Fragment as _Fragment, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1205f0fe"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "ant-row",
  style: {"flex-wrap":"nowrap"}
}
const _hoisted_2 = {
  key: 0,
  class: "ant-col ant-col-xs-1 ant-col-xl-1"
}
const _hoisted_3 = {
  key: 0,
  class: "ant-col ss-div-icone pequeno"
}
const _hoisted_4 = {
  key: 1,
  class: "ant-col ss-div-icone"
}
const _hoisted_5 = {
  key: 2,
  style: {"margin-left":"10px"}
}
const _hoisted_6 = { class: "ant-col ss-div-totalizador" }
const _hoisted_7 = { class: "ss-totalizador" }
const _hoisted_8 = { class: "ss-titulo" }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { class: "ss-valor" }
const _hoisted_11 = {
  key: 1,
  class: "ss-valor"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icone = _resolveComponent("icone")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.props.cor !== '')
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", {
            class: "ant-divider ant-divider-vertical ss-barra-lateral",
            style: _normalizeStyle('background: ' + _ctx.props.cor),
            role: "separator"
          }, null, 4)
        ]))
      : _createCommentVNode("", true),
    (_ctx.props.icone !== '')
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          (_ctx.props.iconePequeno)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                (_ctx.props.iconeColorido)
                  ? (_openBlock(), _createBlock(_component_icone, {
                      key: 0,
                      class: "ss-icone pequeno",
                      nome: _ctx.props.icone,
                      style: _normalizeStyle('color: ' + _ctx.props.cor)
                    }, null, 8, ["nome", "style"]))
                  : (_openBlock(), _createBlock(_component_icone, {
                      key: 1,
                      class: "ss-icone pequeno",
                      nome: _ctx.props.icone
                    }, null, 8, ["nome"]))
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                (_ctx.props.iconeColorido)
                  ? (_openBlock(), _createBlock(_component_icone, {
                      key: 0,
                      class: "ss-icone",
                      nome: _ctx.props.icone,
                      style: _normalizeStyle('color: ' + _ctx.props.cor)
                    }, null, 8, ["nome", "style"]))
                  : (_openBlock(), _createBlock(_component_icone, {
                      key: 1,
                      class: "ss-icone",
                      nome: _ctx.props.icone
                    }, null, 8, ["nome"]))
              ]))
        ], 64))
      : (_openBlock(), _createElementBlock("div", _hoisted_5)),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.props.titulo), 1),
        (_ctx.props.monetario)
          ? (_openBlock(), _createElementBlock("span", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.UtilitarioMascara.mascararValor(_ctx.props.valor,_ctx.props.casasDecimais,false,true)), 1)
            ]))
          : (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(_ctx.UtilitarioMascara.mascararNumeroInteiro(_ctx.props.valor)), 1))
      ])
    ])
  ]))
}